import { graphql } from "gatsby"
import React from "react"
import { Col, Container, Row, media } from "styled-bootstrap-grid"
import styled from "styled-components"

import {
  Layout,
  QuestionsBlock,
  SEO,
  SideBar,
  SocialMedia,
} from "../components"

const FAQ = ({ data }) => {
  const pageTitle = data.post.nodes[0].title
  const pageDescription = data.post.nodes[0].content.content
  const socialMedias = data.socialMedias.nodes[0]
  const sections = data.questions.group.map(group => {
    return {
      selectedItem: 0,
      sectionTitle: group.fieldValue,
      sectionItems: group.nodes.map(node => ({
        answer: node.answer.answer,
        question: node.question.question,
        slug: node.slug,
      })),
    }
  })

  return (
    <Layout>
      <SEO title="FAQ" />
      <StyledFaqContainer fluid>
        <Row>
          <Col xs="10" sm="10" md="4">
            {/* Add the search functionalities for pages that contains a lot of data */}
            <SideBar sections={sections} />
          </Col>
          <Col xs="12" sm="12" md="8">
            <QuestionsBlock
              sections={sections}
              pageTitle={pageTitle}
              pageDescription={pageDescription}
            />
          </Col>
        </Row>
      </StyledFaqContainer>
      <SocialMedia socialMedias={socialMedias} />
    </Layout>
  )
}

const StyledFaqContainer = styled(Container)`
  margin-top: 90px;
  h1 + p {
    margin-top: 110px;
  }
  h2 {
    font-size: 16px;
  }
  ${media.md`
    margin-top: 135px;
    padding-right:100px;
    h2 {
      font-size: 18px
    }
    h1 + p {
      margin-top: 50px;
    }
  `}
`

export default FAQ

export const query = graphql`
  query faqQuery(
    $page: ContentfulPageFilterListInput = {
      elemMatch: { title: { eq: "FAQ" } }
    }
  ) {
    post: allContentfulPost(
      filter: { page: { elemMatch: { title: { eq: "FAQ" } } } }
    ) {
      nodes {
        ...post
      }
    }
    questions: allContentfulQuestion(
      filter: { page: $page }
      sort: { fields: createdAt, order: ASC }
    ) {
      group(field: subject___title) {
        fieldValue
        totalCount
        nodes {
          ...questions
        }
      }
    }
    socialMedias: allContentfulSocialMediaGroup {
      nodes {
        ...socialMedia
      }
    }
  }
`
